.list {
  width: calc(100% - 40px);
  margin: 0;
  list-style: none;
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  margin-top: 20px;
}

.upload-btn {
  background-color: var(--secondary-color);
  color: #fff;
  font-size: 15px;
  padding: 10px 40px;
  border: 0;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
}

.alert {
  width: 100vw;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(40, 35, 35, 0.75);
  left: 0;
  color: #fff;
  flex-direction: column;
}

.alert p {
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.alert div {
  gap: 10px;
}

.alert button {
  border: 0;
  background: transparent;
  color: white;
  cursor: pointer;
}
