* {
  --primary-color: #ffd700;
  --secondary-color: #4682b4;
}

body {
  margin: 0;
}

.app-wrapper {
  font-family: "Comic Sans MS", cursive, sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: var(--primary-color);
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary-color);
  text-decoration: none;
}

.main {
  flex-grow: 1;
  padding: 2rem;
  background-color: #f0f8ff;
}

.footer {
  background-color: var(--primary-color);
  color: #333;
  padding: 1rem;
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.social-icon {
  color: var(--secondary-color);
  font-size: 1.5rem;
  transition: color 0.3s;
  &:hover {
    color: #36648b;
  }
}

.nav-items {
  display: flex;
  gap: 1rem;
}

.nav-link {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s;
  &:hover,
  &.active {
    background-color: #4682b4;
    color: white;
  }
}

.loader-box {
  width: 100vw;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(40, 35, 35, 0.5);
  left: 0;
}

.loader {
  width: 70px;
  height: 70px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.percentage {
  position: absolute;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
}

.grecaptcha-badge {
  visibility: hidden;
}

.privacy {
  font-size: 8px;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
