.login-wrapper {
  padding: 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  padding: 20px 60px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.login-form h4 {
  font-size: 25px;
  margin: 0;
  margin-bottom: 20px;
}

.login-input {
  padding: 10px;
  font-size: 15px;
  border-radius: 4px;
  display: block;
  margin: 10px 0;
  border-color: transparent;
}

.submit {
  background: var(--primary-color);
  border: 0;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  padding: 5px 0;
  margin-top: 10px;
  border-radius: 4px;
  color: var(--secondary-color);
  cursor: pointer;
  transition: all;
  animation-duration: 300ms;

  &:hover {
    opacity: 0.7;
  }
}
